<template src='./saltreview.html'></template>
<style scoped src='./saltreview.css'></style>

<script>
import TransactionService from '@/services/TransactionService'
import AccountService from '@/services/AccountService'
import Utils from '@/utils/utils'
import loading from 'vue-loading-overlay'
import TransactionConstants from '@/constants/TransactionConstants'
import { required } from 'vuelidate/lib/validators'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'

export default {
    components : {
        loading, modal
    },
    data () {
        return {
            recipientDetails : {
                recipientId : null,
                recipientName : null,
                destCurrency : null,
                remittanceGatewayId : null,
                destCountry : null
            },
            transactionDetails : {
                requestId : null,
                parentReferenceNumber : null,
                sendAmount : null,
                dateTime : null,
                destAmount : null ,
                referenceNumber : null,
                requestedAmount : null,
                serviceCharge : null,
                sourceAmount : null,
                transactionNumber : null,
                transactionType : null,
                phoneNumber : null,
                transNumber : null
            },
            purposeId : null,
            purpose : null,
            userRefId : null,
            selectedPSITransactionStatus : null,
            transactionNotes : [],
            currentPage : 1,
            perPage : 8,
            notes : null,
            loaderVisible : false,
            transNumber : null,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            utils: Utils
        }
    },
    methods : {
        async handleAddNotesEvent() {
            if(this.notes && this.notes.trim() !== '') {
                this.loaderVisible = true
                await TransactionService.methods.AddTransactionNotes(this.notes,this.transactionDetails.referenceNumber)
                .then(() => {
                    this.showAlert(1, 'Notes Added Successfully')
                    this.notes = '' 
                    this.getTransactionDetails()   
                })
                .catch( err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            }else{
                this.showAlert(null, 'Please Enter Notes')
            }
        },
        saltCompliance() {
            this.$v.$touch()
            if(this.$v.$invalid) {
                return
            }
            this.$bvModal.msgBoxConfirm('Are you sure, you want to complete the interac?')
            .then(async result => {
                if(result) {
                    if(this.transNumber && this.$route.params.SenderEmail) {
                        await TransactionService.methods.CompleteSaltTransaction({
                            RequestId : Utils.methods.getRandomNumber(),
                            TransactionId : this.transNumber,
                            InvoiceId : this.transactionDetails.referenceNumber,
                            CustomerEmail : this.$route.params.SenderEmail,
                            PsiTransactionStatus : this.selectedPSITransactionStatus,
                            Amount : this.transactionDetails.sourceAmount
                        }).then(async response => {
                            if(response.csSaltCompleteTransactionDataResponse.SendMoneyRequest) {
                                if( response.csSaltCompleteTransactionDataResponse.PromoCodeData?.Code) {
                                    if(this.recipientDetails.remittanceGatewayId) {
                                        this.recipientDetails.remittanceGatewayId = response.csSaltCompleteTransactionDataResponse.PromoCodeData?.RemittanceGateway._text;
                                    }
                                    if(response.csSaltCompleteTransactionDataResponse.PromoCodeData.Code._text
                                    && response.csSaltCompleteTransactionDataResponse.PromoCodeData.Issue._text
                                    && this.recipientDetails.remittanceGatewayId && this.transactionDetails.sendAmount) {
                                        //csRedeemPromocodeapicall
                                        await TransactionService.methods.RedeemPromocode({
                                            RequestId : Utils.methods.getRandomNumber(),
                                            CustomerEmail : this.$route.params.SenderEmail,
                                            Code : response.csSaltCompleteTransactionDataResponse.PromoCodeData.Code._text,
                                            IssueNumber : response.csSaltCompleteTransactionDataResponse.PromoCodeData.Issue._text,
                                            ParentReferenceNumber : this.transactionDetails.ParentReferenceNumber,
                                            ValidateOnly : 'false',
                                            RemittanceGateway : this.recipientDetails.remittanceGatewayId,
                                            Amount : this.transactionDetails.sendAmount
                                        }).then(async rPromoResponse => {
                                            //call sendmoney 
                                            await this.sendmoneyAftersaltCompletion({ 
                                                OriginCurrency : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.OriginCurrency?._text,
                                                RecipientId : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.RecipientId?._text,
                                                XferAmount : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.XferAmount?._text,
                                                CustomerEmail : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.CustomerEmail?._text,
                                                MRMSSession : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.SessionId?._text,
                                                Purpose : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.Purpose?._text,
                                                ParentReferenceNumber : this.transactionDetails.parentReferenceNumber,
                                                RequestedDestinationAmount : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.RequestedDestinationAmount?._text
                                            })
                                        })
                                        .catch(error => {
                                         this.showAlert(null, 'Interac Compliance process went through successfully and amount credited in customer Account. While processing Promo Redemption following error occured')   
                                        })
                                        //RedPromoResponse errror should be listed.
                                    } 
                                }else{
                                    //call sendmoney
                                    await this.sendmoneyAftersaltCompletion({ 
                                        OriginCurrency : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.OriginCurrency?._text,
                                        RecipientId : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.RecipientId?._text,
                                        XferAmount : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.XferAmount?._text,
                                        CustomerEmail : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.CustomerEmail?._text,
                                        MRMSSession : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.SessionId?._text,
                                        Purpose : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.Purpose?._text,
                                        ParentReferenceNumber : this.transactionDetails.parentReferenceNumber,
                                        RequestedDestinationAmount : response.csSaltCompleteTransactionDataResponse.SendMoneyRequest.RequestedDestinationAmount?._text
                                    })
                                }
                            }else{
                                this.showAlert(null, 'QicSend Says','Invalid Transaction Details. Not able to process Interac Compliance.')
                            }
                        }).catch(error =>{
                            this.alertObject = error
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                    }else{
                        this.showAlert(null, 'Invalid Transaction Details. Not able to process Interac Compliance.')
                    }
                }
            })
        },
        sendmoneyAftersaltCompletion(sendMoneyParams) {
            //check IsDisabled Bank
            //send money api call 
            TransactionService.methods.SendMoney({
                RequestId : Utils.methods.getRandomNumber(),
                OriginCurrency : sendMoneyParams.OriginCurrency,
                RecipientId : sendMoneyParams.RecipientId,
                XferAmount : sendMoneyParams.XferAmount,
                CustomerEmail : sendMoneyParams.CustomerEmail,
                Purpose : sendMoneyParams.Purpose,
                ParentReferenceNumber : sendMoneyParams.ParentReferenceNumber,
                RequestedDestinationAmount : sendMoneyParams.RequestedDestinationAmount,
                MRMSSession : sendMoneyParams.MRMSSession ? sendMoneyParams.MRMSSession : null
            }).then(res => {
                //if valid means to redirect to confirmation page
                this.$router.push({
                    name: 'Confirmation',
                    params: {
                        resSendMoney : res.csSendMoneyResponse.TransactionData,
                        parentReferenceNumber : res.csSendMoneyResponse.ParentReferenceNumber
                    }
                })
            }).catch(error =>{
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        getTransactionDetails() {  
            TransactionService.methods.GetTransactionDeatils({
                CustomerEmail : this.$route.params.SenderEmail,
                TransactionId : this.$route.params.TransactionId
            }).then(res => {
                this.transactionNotes = Object.keys(res.csTransactionDetailsResponse.TransactionNotes).length > 0 ? Array.isArray(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote) ? (res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote).map(s => ({ CreateDateUtc: s.CreateDateUtc._text, CreatedBy: s.CreatedBy._text, Note: s.Note._text })) : [{
                    CreateDateUtc : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreateDateUtc._text,
                    CreatedBy  : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreatedBy._text,
                    Notes  :  res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.Note._text
                }] : []
            })
            .catch(er =>{
                this.alertObject = er
                this.$bvModal.show(this.$refs.childModal.id)
            })
            this.loaderVisible = false
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
    },
    computed : {
        rows () {
            return this.transactionNotes?.length
        }
    },
    async created () {
        if(this.$route.params?.ReferenceNumber) {
            try{
                this.loaderVisible = true
                let reducer = (accumulator, currentValue) => {
                    return (accumulator === currentValue?.Value) ? currentValue?.Text : accumulator
                }
                let userProfileResponse = await AccountService.methods.GetAccountDetails(this.$route.params.SenderEmail)
                this.userRefId = userProfileResponse.csGetUserProfileResponse.UserRefId._text
                this.transactionDetails.parentReferenceNumber = this.$route.params.ParentReferenceNumber
                await TransactionService.methods.GetTransactionDeatils({
                    RequestId : Utils.methods.getRandomNumber(),
                    CustomerEmail : this.$route.params.SenderEmail,
                    TransactionId : this.$route.params.TransactionId
                }).then(response => {
                    if(response.csTransactionDetailsResponse.TransactionData) {
                        if(response.csTransactionDetailsResponse.TransactionData.TransactionType._text === 'From Bank Account') {
                            if(response.csTransactionDetailsResponse.TransactionData.RemittanceData._text) {
                                let MoneyTransferRequestSavedData = Utils.methods.getParseObjectFromXml(response.csTransactionDetailsResponse.TransactionData.RemittanceData._text)
                                if(MoneyTransferRequestSavedData) {
                                    this.recipientDetails.recipientId = MoneyTransferRequestSavedData.MoneyTransferRequestSavedData.RecipientId?._text
                                    this.purposeId = MoneyTransferRequestSavedData.MoneyTransferRequestSavedData.Purpose?._text
                                    this.purpose = TransactionConstants.transactionPurpose.reduce(reducer, this.purposeId)
                                    this.transactionDetails.sendAmount = MoneyTransferRequestSavedData.MoneyTransferRequestSavedData.RequestedAmount?._text
                                }
                                TransactionService.methods.GetRecipientsDetails(this.$route.params.SenderEmail,'','')
                                .then(recipients => {
                                    let Recipients = Array.isArray(recipients.csGetRecipientsResponse.RecipientItems?.RecipientItem) ? recipients.csGetRecipientsResponse.RecipientItems.RecipientItem : [recipients.csGetRecipientsResponse.RecipientItems.RecipientItem]
                                    let RecipientDetails = Recipients.find(item => item.Id?._text === this.recipientDetails.recipientId)
                                    this.recipientDetails.recipientName = RecipientDetails.FirstName?._text
                                    this.recipientDetails.destCurrency = RecipientDetails.RemittanceCurrency?._text
                                    this.recipientDetails.remittanceGatewayId = RecipientDetails.RemittanceGatewayId?._text
                                    this.recipientDetails.destCountry = RecipientDetails.Country?._text
                                }).catch(error =>{
                                    this.alertObject = error
                                    this.$bvModal.show(this.$refs.childModal.id)
                                })
                            }                        
                        }
                    }
                    this.transactionDetails.requestId = response.csTransactionDetailsResponse.RequestId?._text
                    this.transactionDetails.dateTime = Utils.methods.UTCtoLocal(moment(moment(response.csTransactionDetailsResponse.TransactionData.DateTime?._text, 'MM/DD/YYYY HH:mm:ss')._d).format( 'MM-DD-YYYY HH:mm:ss'))
                    this.transactionDetails.destAmount = response.csTransactionDetailsResponse.TransactionData.DestAmount?._text
                    this.transactionDetails.referenceNumber = response.csTransactionDetailsResponse.TransactionData.ReferenceNumber?._text
                    this.transactionDetails.requestedAmount = response.csTransactionDetailsResponse.TransactionData.RequestedAmount?._text
                    this.transactionDetails.serviceCharge = response.csTransactionDetailsResponse.TransactionData.ServiceCharge?._text
                    this.transactionDetails.sourceAmount = response.csTransactionDetailsResponse.TransactionData.SourceAmount?._text
                    this.transactionDetails.transactionNumber = response.csTransactionDetailsResponse.TransactionData.TransactionNumber?._text
                    this.transactionDetails.transactionType = response.csTransactionDetailsResponse.TransactionData.TransactionType?._text
                    this.transactionDetails.phoneNumber = response.csTransactionDetailsResponse.TransactionData.PhoneNumber?._text
                    if(response.csTransactionDetailsResponse.TransactionNotes) {
                         this.transactionNotes = Object.keys(response.csTransactionDetailsResponse.TransactionNotes).length > 0 ? Array.isArray(response.csTransactionDetailsResponse.TransactionNotes.csTransactionNote) ? (response.csTransactionDetailsResponse.TransactionNotes.csTransactionNote).map(s => ({ CreateDateUtc: s.CreateDateUtc._text, CreatedBy: s.CreatedBy._text, Note: s.Note._text })) : [{
                            CreateDateUtc : response.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreateDateUtc._text,
                            CreatedBy  : response.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreatedBy._text,
                            Notes  :  response.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.Note._text
                        }] : []
                    }

                }).catch(error =>{
                    this.alertObject = error
                    this.$bvModal.show(this.$refs.childModal.id)
                })
 
                // code for formatting mobile number
                // database dependency for IpAddress,Mrms,DeviceInfo
                this.loaderVisible = false
            }catch(ex) {
                this.loaderVisible = false
            }
        }else{
            this.$router.push({
                name: 'CompleteInteracPSIGate',
            })
        } 
    },
    validations : {
        selectedPSITransactionStatus : {required},
        transNumber : {required}
    }
}
</script>
